<template>
  <div class="data-moniter-crops">
    <Header />
    <div class="header">
      <div class="tab" :class="{'active': tabIndex === 0}" @click="tabIndex = 0">终端</div>
      <div class="tab" :class="{'active': tabIndex === 1}" @click="tabIndex = 1">种植大户</div>
    </div>
    <div id="myChart" class="crops-chart"></div>
  </div>
</template>

<script>
const echarts = require('echarts')
require('echarts/lib/chart/bar')
require('echarts/lib/component/tooltip')
import Header from '../../../components/header';
export default {
  components: {
    Header,
  },
  data() { 
    return {
      tabIndex: 0,
    }
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
          color: ['#F56C6C', '#E5A23C'],
          title: { 
            subtext: '终端作物TOP10',
            textStyle: {
              fontSize: '13px',
              fontWeight: '500'
            },
            x: 'center'
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: ["小麦","水稻","作物",]
          },
          yAxis: {},
          series: [{
            name: '终端作物',
            type: 'bar',
            data: [3, 10, 26],
          }]
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.data-moniter-crops {
  min-height: 100vh;
  background-color: #FAFBFC;
  .header {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 76px;
    box-sizing: border-box;
    background-color: #fff;
    .tab {
      width: 81px;
      height: 29px;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      border: 1px solid transparent;
      text-align: center;
      color: #818181;
      &.active {
        color: #CD001F;
        border: 1px solid #CD001F;
      }
    }
  }
  .crops-chart {
    width: 100%;
    height: 300px;
    margin-top: 6px;
    background-color: #fff;
  }
}
</style>